import React, { useState } from 'react'
import styled from 'styled-components'
import { BusinessUnit, CreateAccountRequestData } from '../../services/Dtos'
import { createAccount } from '../../services/AccountsService'
import { useNavigate } from 'react-router-dom'
import { logError } from '../../lib/logError'

const Field = styled.div`
  margin-bottom: var(--spacing-md);

  label {
    display: block;
    margin-bottom: var(--spacing-sm);
  }
`

type Props = {
  businessUnits: BusinessUnit[]
}

const UNASSIGNED_BU_ID = '9999'

export const AddAccountForm = ({ businessUnits }: Props) => {
  const navigate = useNavigate()
  const [creating, setCreating] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const initialBusinessUnit = businessUnits.length === 1 ? businessUnits[0].id : UNASSIGNED_BU_ID
  const [account, setAccount] = useState<CreateAccountRequestData>({
    businessUnitId: initialBusinessUnit,
    externalId: '',
    name: '',
    description: '',
    scopes: []
  })

  const createNewAccount = () => {
    setError('')
    setCreating(true)

    createAccount({ ...account })
      .then(response => {
        setCreating(false)
        navigate(`/accounts/${response.id}`)
      })
      .catch(error => {
        logError(error)
        setError(error.message)
        setCreating(false)
      })
  }

  return (
    <React.Fragment>
      <h1 className="text-xl mb-md">Create New Account</h1>

      <Field>
        <label htmlFor="businessUnit">Business Unit</label>
        <select
          id="businessUnit"
          className="select"
          onChange={({ target }) => setAccount({ ...account, businessUnitId: target.value })}
          value={account.businessUnitId}
        >
          {businessUnits
            .sort((a, b) => a.id.localeCompare(b.id))
            .map(businessUnit => (
              <option key={businessUnit.id} value={businessUnit.id} title={businessUnit.countries.join(', ')}>
                {businessUnit.ifsNumber == null
                  ? businessUnit?.name ?? ''
                  : businessUnit.ifsNumber + ': ' + businessUnit.name}
              </option>
            ))}
        </select>
      </Field>

      <Field>
        <label htmlFor="externalId">External ID</label>
        <input
          id="externalId"
          type="text"
          value={account.externalId}
          onChange={({ target }) => setAccount({ ...account, externalId: target.value })}
          onBlur={({ target }) => setAccount({ ...account, externalId: target.value.trim() })}
          placeholder="Customer Number / ID"
        />
      </Field>

      <Field>
        <label htmlFor="accountName">Account Name</label>
        <input
          id="accountName"
          type="text"
          value={account.name}
          onChange={({ target }) => setAccount({ ...account, name: target.value })}
          onBlur={({ target }) => setAccount({ ...account, name: target.value.trim() })}
        />
      </Field>

      <Field>
        <label htmlFor="description">Description</label>
        <input
          id="description"
          type="text"
          value={account.description}
          onChange={({ target }) => setAccount({ ...account, description: target.value })}
          onBlur={({ target }) => setAccount({ ...account, description: target.value.trim() })}
        />
      </Field>

      {error && <div className="alert alert-danger my-md">{error}</div>}

      <div className="mt-md flex justify-end">
        <button
          className="btn btn-primary-dark"
          onClick={createNewAccount}
          type="submit"
          disabled={creating || account.businessUnitId === UNASSIGNED_BU_ID}
        >
          {creating ? <div className="loadingSpinner" /> : 'Create'}
        </button>
      </div>
    </React.Fragment>
  )
}
