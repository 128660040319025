import { authenticatedGet, authenticatedPost, authenticatedPut, defaultHttpErrorHandler } from '../lib/httpClient'
import { API_HOST } from '../lib/environment'
import { FoundUser } from '../lib/UserHooks'

const SEARCH_FOR_USER = `${API_HOST}/user/search?searchTerm={searchTerm}`
const CREATE_USER = `${API_HOST}/user`
export const GET_USER_DETAILS = `${API_HOST}/user/details/{userId}`

async function searchForUsers(searchTerm: string): Promise<FoundUser[]> {
  const url = SEARCH_FOR_USER.replace('{searchTerm}', encodeURIComponent(searchTerm))

  // noinspection TypeScriptValidateJSTypes
  return authenticatedGet(url)
    .run()
    .then(parsedBody => parsedBody.users)
    .catch(() => undefined)
}

async function createUser(
  email: string,
  firstName: string,
  lastName: string,
  password?: string,
  redirect?: { uri: string; clientId: string },
  requireUpdatePassword?: boolean
): Promise<void> {
  return authenticatedPost(
    CREATE_USER,
    {
      email,
      firstName,
      lastName,
      password,
      requireUpdatePassword,
      redirect
    },
    true
  )
    .run()
    .catch(httpError => {
      if (httpError.status === 409) {
        throw new Error('User already exists, try searching for the e-mail again')
      }
      defaultHttpErrorHandler(httpError)
    })
}

async function sendPasswordResetEmail(userId: string) {
  return authenticatedPut(`${API_HOST}/user/reset-password-mail/${userId}`, {})
    .run()
    .catch(httpError => {
      if (httpError.status >= 400) {
        throw new Error('Unable to reset password for user')
      }
      defaultHttpErrorHandler(httpError)
    })
}

export const UserService = {
  createUser,
  sendPasswordResetEmail,
  searchForUsers
}
