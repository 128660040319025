import React, { FormEvent, useState } from 'react'
import { TransitionToNewProduct } from '../../../services/Dtos'
import { LicenseService } from '../../../services/LicenseService'

export default function TransitionToNewProductForLicenseForm({
  licenseId,
  transitionToNewProduct,
  onSaved
}: {
  licenseId: string
  transitionToNewProduct: TransitionToNewProduct
  onSaved: () => void
}) {
  const [orderedBy, setOrderedBy] = useState(transitionToNewProduct.orderedBy ?? '')
  const [ticketNo, setTicketNo] = useState(transitionToNewProduct.orderedBy ?? '')
  const [errorMessage, setErrorMessage] = useState('')

  const submit = (e: FormEvent) => {
    e.preventDefault()
    setErrorMessage('')
    LicenseService.transitionLicenseToNewProduct(licenseId, transitionToNewProduct.targetProduct, orderedBy, ticketNo)
      .then(onSaved)
      .catch(_ => {
        setErrorMessage(`Setting new product ${transitionToNewProduct.targetProduct} failed.`)
      })
  }

  return (
    <form className="flex gap-md items-center content-between" onSubmit={submit}>
      <div>
        <label className="block mb-sm" htmlFor="orderedBy">
          Ordered By
        </label>
        <input
          id="orderedBy"
          type="text"
          name="orderedBy"
          value={orderedBy}
          onChange={({ target }) => setOrderedBy(target.value)}
          onBlur={({ target }) => setOrderedBy(target.value.trim())}
        />
      </div>

      <div>
        <label className="block mb-sm" htmlFor="ticketNo">
          Support Ticket #
        </label>
        <input
          id="ticketNo"
          type="text"
          name="ticketNo"
          value={ticketNo}
          onChange={({ target }) => setTicketNo(target.value)}
          onBlur={({ target }) => setTicketNo(target.value.trim())}
        />
      </div>
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <button type="submit" className="btn btn-success mt-lg">
        Submit
      </button>
    </form>
  )
}
