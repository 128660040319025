import React, { useState } from 'react'
import { useRemoteData } from '../../lib/RemoteData'
import { GET_USER_DETAILS, UserService } from '../../services/UserService'
import { Link, useParams } from 'react-router-dom'
import { UserDetails } from '../../services/Dtos'
import { FormattedDateTime } from '../../components/FormattedDateTime'
import { userIsAdmin } from '../../lib/jwt'

export const UserDetailsPage = () => {
  const { id = '' } = useParams<{ id: string }>()
  const { data } = useRemoteData<UserDetails>(GET_USER_DETAILS.replace('{userId}', id || 'bla'))
  const [isLoadingReset, setIsLoadingReset] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const userHasFullResourceAccess = userIsAdmin()

  if (data.type === 'loading') {
    return (
      <div className="card p-md centerFlex">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (data.type === 'error') {
    return (
      <div className="centerAbsolute">
        <div className="alert alert-danger">Uh-oh.. Failed to load accounts</div>
      </div>
    )
  }

  return (
    <div>
      {error ? <p className="p-md text-red">{error}</p> : null}
      <div className="card p-md mb-md">
        <h3 className="inline-block">{`${data.value.user.firstName} ${data.value.user.lastName} (${data.value.user.email})`}</h3>
        {userHasFullResourceAccess && (
          <button
            aria-label="Send password reset e-mail"
            className="btn btn-primary-dark flex items-center gap-sm mt-md"
            onClick={() => {
              setError(undefined)
              setIsLoadingReset(true)
              UserService.sendPasswordResetEmail(data.value.user.id)
                .then(value => {
                  setIsLoadingReset(false)
                })
                .catch(error => {
                  setError(error.message)
                  setIsLoadingReset(false)
                })
            }}
          >
            {isLoadingReset ? <div className="loadingSpinner" /> : 'Send password reset e-mail'}
          </button>
        )}
      </div>
      <div className="card p-md">
        <table className="table">
          <thead>
            <tr>
              <th>Account</th>
              <th>Product</th>
              <th>User Role</th>
              <th>Activated</th>
              <th>Expires</th>
            </tr>
          </thead>
          <tbody>
            {data.value.licenses.map((userLicense, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Link className="link" to={'/accounts/' + userLicense.accountId}>
                      {userLicense.accountName}
                    </Link>
                  </td>
                  <td>{userLicense.productName}</td>
                  <td>{userLicense.role}</td>
                  <td>
                    <FormattedDateTime timestamp={userLicense.activated} />
                  </td>
                  <td>{userLicense.expires && <FormattedDateTime timestamp={userLicense.expires} />}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
